import { RestService } from '~/services/RestService'
import Endpoints from '~/models/constants/Endpoints'

class FavoriteService extends RestService {
  async getFavorites(username: string, currentPage: number, pageSize: number) {
    const response = await this.findSecured(`${Endpoints.FAVORITES}/spaces`, {
      params: { username, page: currentPage, size: pageSize },
    })

    return this.handleResponse(response)
  }

  async getFavoritesCount(username: string) {
    const response = await this.findSecured(
      `${Endpoints.FAVORITES}/spaces/count`,
      {
        params: { username },
      }
    )

    return this.handleResponse(response)
  }

  async addFavorite(spaceId: number) {
    const response = await this.postSecured(
      `${Endpoints.FAVORITES}`,
      {
        spaceId,
        type: 'SPACE',
      },
      {}
    )

    return this.handleResponse(response)
  }

  async removeFavorite(spaceId: string) {
    const response = await this.delete(`${Endpoints.FAVORITES}/${spaceId}`, {})

    return this.handleResponse(response)
  }
}
export default new FavoriteService()
